import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/cruiseota_logo.png";

const IndexPage = () => (
    <Layout>
        <SEO title="cruiseota.com"/>

        <div className={"page-header home"}>
            <img alt={"Dashboard"} src={featureImage}/>
        </div>

        <div className={"container"}>

        </div>

    </Layout>
)

export default IndexPage
